import React, { useState } from 'react';
import {
  Accordion,
  Button,
  ListGroup,
  Dropdown,
  Card,
  FormControl,
  AccordionContext,
  Tooltip, OverlayTrigger,
} from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { HiFolder, HiFolderOpen } from 'react-icons/hi';
import { graphql, StaticQuery } from 'gatsby';

class Side extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
        query {
          allDataJson(sort: {order: DESC, fields: version}) {
            nodes {
              version
              assembly_name
            }
          }
        }
      `}
        render={(data) => {
          const allVersions = data.allDataJson.nodes.filter(
            ({ assembly_name }) => assembly_name === this.props.apiList.assembly_name,
          );
          const basePath = this.props.apiList.assembly_name.toLowerCase() + '@';
          const listItem = (data, value, include) => (include ?
            (
              <ListGroup.Item
                as={Button}
                style={{ maxWidth: '100%' }}
                href={`/api/${basePath}${this.props.apiList.version}/${data.name.toLowerCase().replaceAll('+', '-')}/`}
                key={data.name}
                eventKey={data.name.toLowerCase()}>
                {data.name.replace('+', '.')}
              </ListGroup.Item>
            ) : null);
          const sortedApiList = this.props.apiList.data.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
          const [value, setValue] = useState('');

          return (
            <>
              <Accordion defaultActiveKey='0' className='side-list'>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey={'0'}>
                      {this.props.apiList.assembly_name}
                    </ContextAwareToggle>
                  </Card.Header>
                  <Card.Body style={{ padding: '0px' }}>
                    <Dropdown>
                      <Dropdown.Toggle style={{ width: '100%', borderColor: 'transparent' }}
                                       variant={'outline-primary'}>{this.props.apiList.version}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {allVersions.map(({ version }) => (
                          <Dropdown.Item
                            key={version}
                            href={`/api/${basePath}${version}`}>{version}
                          </Dropdown.Item>))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Card.Body>
                  <Accordion.Collapse eventKey={'0'}>
                    <ListGroup variant={'flush'} activeKey={this.props.currentKey}>
                      <FormControl size='sm' placeholder={'Search...'}
                                   onChange={(e) => setValue(e.target.value)}
                                   value={value} style={{ marginBottom: '0.3rem' }} />
                      {sortedApiList.map(api => {
                        const isIncluded = api.name.toLowerCase().match(value.toLowerCase()) != null;
                        return (
                          api.comment.length && isIncluded
                            ? (<OverlayTrigger
                              key={api.name + '_trigger'}
                              overlay={<Tooltip id={api.name + '_comment'}>{api.comment}</Tooltip>}>
                              {listItem(api, value, isIncluded)}
                            </OverlayTrigger>)
                            : (listItem(api, value, isIncluded))
                        );
                      })}
                    </ListGroup>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </>
          );
        }}
      >
      </StaticQuery>
    );
  }
}

export default Side;

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = React.useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Accordion.Toggle as={Button} variant={'link'}
                      onClick={decoratedOnClick}
                      className='side-header'>
      {isCurrentEventKey ? <HiFolderOpen /> : <HiFolder />} {children}
    </Accordion.Toggle>
  );
}
