import React from 'react';
import NavBar from './nav';
import '../styles/style.scss';
import { graphql, StaticQuery } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import Side from './side';
import Head from './head';

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StaticQuery query={graphql`
       query {
        allDataJson {
          nodes {
            version
            assembly_name
            data {
              name
              comment
            }
          }
        }
      }
`} render={data => {
        return (
          <>
            <NavBar />
            <Head title={`${this.props.title} | DevPlay SDK API Documentation`} />
            <Container style={{ paddingTop: '4rem' }}>
              <Row>
                {this.props.showSide
                  ? (
                    <>
                      <Col md={3}>
                        <Side
                          apiList={data.allDataJson.nodes.find(node => (
                            node.version === this.props.version && node.assembly_name === this.props.assembly_name
                            ))}
                          currentKey={this.props.path} />
                      </Col>
                      <Col md={9} style={{ maxWidth: '100%', paddingTop: '25px' }}>
                        {this.props.children}
                      </Col>
                    </>
                  ) : (this.props.children)}
              </Row>
            </Container>
          </>
        );
      }
      }>
      </StaticQuery>
    );
  }
}

export default Layout;
