import React from 'react';
import { StaticQuery } from 'gatsby';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation } from '@reach/router';

const NavigationBar = () => {
  const current_path = useLocation().pathname.split('/')[1];

  return (
    <StaticQuery
      query={graphql`
    query {
      allDataJson(sort: {fields: version, order: DESC}) {
        group(field: assembly_name, limit: 1) {
          nodes {
            assembly_name
            version
          }
        }
      }
    }
    `}
      render={({ allDataJson: allAssemblyData }) => (
        <Navbar bg={'primary'} variant={'dark'} fixed={'top'} expand={'lg'} style={{ padding: '8px 0px' }}>
          <Container>
          <img
            src={'/devplay_logo.png'}
            width={'30'}
            height={'30'}
            alt='' />
          <Navbar.Brand style={{ margin: '0px 10px', fontSize: '1rem' }}>
            DevPlay SDK API Doc
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' style={{padding : '5px'}}/>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav
              activeKey={'/' + current_path}
              className='me-auto my-2 my-lg-0'
            >
              <Nav.Link href={'/'} variant={'danger'}>About</Nav.Link>
              <NavDropdown title={'Scripting API'} active={current_path === 'api'}>
                {allAssemblyData.group.map((assem) => {
                    const routingName = assem.nodes[0].assembly_name.toLowerCase() + '@' + assem.nodes[0].version;
                    return (
                      <NavDropdown.Item
                        href={`/api/${routingName}`}
                        key={assem.nodes[0].assembly_name}>
                        {assem.nodes[0].assembly_name}
                      </NavDropdown.Item>
                    );
                  },
                )}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    >
    </StaticQuery>
  );
};

export default NavigationBar;
